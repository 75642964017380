.navbar-container{
    background-color: rgba(13, 13, 14, 1);
    font-family: 'Inter', sans-serif;
    padding-top: 20px;
    position: fixed;
    width: 100%;
    z-index: 1000;
    
}
.navbar{
    display: flex;
    background-color:rgba(13, 13, 14, 1);
    justify-content: space-between;
    margin-left: 150px;
    margin-right: 150px;
    align-items: center;
    
    
    
;
    ul{
        display: flex;
        color: white;
        list-style: none;
        // background-color: red;
        li{
            padding-left: 20px;
            padding-right: 20px;
            font-size: 16px;
            .navlink{
                color: white;
                text-decoration: none;
                :active{
                    color: purple;
                }
                    
                
                
            }
           
        }
        
    }
    button{
        // background-color: blue;
        background-color: inherit;
        color: white;
        border: 2px solid white;
        padding: 5px;
        width: 182px;
        height: 49px;
        font-size: 17.5px;
        &:hover{
            cursor: pointer;
        }
    }
}
.mobile-x-case{
    display: none;

    .mobile-x{
        color: white;
    }
}
.mobile-hamburger-case{
    display: none;
    .mobile-hamburger{
        color: white;
    }
}

//Nest Hub
@media screen and (max-width: 1025px) {
    .navbar{
        margin-left: 80px;
        margin-right: 80px;
        ul{
            li{
                font-size: 12px;
            }
        }
        button{
            width: 160px;
            height: 40px;
            font-size: 16px;
        }
    }
    
}

@media screen and (max-width: 913px){
    .navbar{
        margin-right: 50px;
        margin-left: 50px;
    }
    .navigation-links{
        display: none;
    }
    
    .cv{
        display: none;
    }

    .mobile-hamburger-case{
        display: block;
    }
    .mobile-x-case{
        display: block;
        position: relative;
    }
   
    .navigation-mobile{
        position: absolute;
        top: 70px;
        left:0px;
        background-color: rgba(13, 13, 14, 1);
        width: 100vw;
        height: 300px;
        .cv-mobile{
            margin-left: 60px;
            width: 150px;
            
        }
    }
    ul{
        flex-direction: column;
        position: relative;
        padding-top: 20px;
        padding-left: 40px;
        li{
            padding-top: 15px;
            .navlink{
                font-size: 16px;
            }
            
        }
        
       
    }
    
   
}
//Mobile phones
@media screen and (max-width: 540px){
    .navbar{
        img{
            width: 60px;
        }
        .navigation-mobile{
            .cv-mobile{
                margin-left: 50px;
                width: 120px;
                font-size: 13px;
            }
        }
        ul{
            padding-left: 30px;
        }
    }

}
@media screen and (max-width: 290px) {
      .navbar{
        margin-left: 20px;
        margin-right: 20px;
        .navigation-mobile{
            .cv-mobile{
                margin-left: 30px;
            }
        }
        ul{
            padding-left: 10px;
        }
      }
}