.footer-section{
    font-family: "Inter", sans-serif;
    background-color: rgba(13, 13, 14, 1);
    color: white;
    padding-top: 50px;
    display: flex;
    justify-content: center;
    text-align: center;
    padding-bottom: 50px;
    
    .footer{
        .social-media-icons{
            display: flex;
            justify-content: space-between;
            width: 80%;
            margin-left: 20px;
            margin-top: 20px;
            margin-bottom: 20px;
            a{
                color: white;
                :hover{
                    color: darkorchid;
                }
            }

            .icons{
                font-size: 30px;
            }
        }
        h5{
            all: initial;
            color: white;
            font-family: "Inter", sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }
        p{
            all: initial;
            color: white;
            font-size: 18px;
            font-weight: 300px;
            margin-top: 20px;
            font-family: "Inter", sans-serif;
            color: grey;
        }

    }
}

@media screen and (max-width: 300px) {
    .footer-section{
        .footer{
          h5{
            font-size: 15px;
          }
          .social-media-icons{
            width: 60%;
            margin-left: 40px;
            .icons{
                font-size: 18px;
            }
          }
          p{
            font-size: 12px;
          }
        }

    }
}