.about-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(13, 13, 14, 1);
  padding-top: 100px;
  padding-left: 150px;
  padding-right: 150px;
  font-family: "Inter", sans-serif;

  img {
    height: 50vh;
    width: 28vw;
  }
  .about-text {
    color: white;
    width: 35vw;
    h1 {
      background: linear-gradient(92.11deg, #6a35f1 25.9%, #bc3ffe 84.84%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      font-size: 38px;
    }
    p{
      font-size: 22px;

    }
    button{
        width: 180px;
        height: 56px;
        font-size: 21px;
        background-color: inherit;
        border-color: white;
        color: white;
        &:hover{
          cursor: pointer;
          background-color: white;
          color: black;
        }
    }
  }
}

@media screen and (max-width: 912px) {
  .about-section {
    display: block;
    padding-left: 0px;
    padding-right: 0px;
    .about-text{
        
        width: 100%;
        text-align: center;
        p{
          padding-left: 40px;
          padding-right: 40px;
          // font-size: 15.2px;

          
        }
        
    }
    img{
        width: 100%;
        height: 38vh;
        
    }
  }
}
@media screen and (max-width: 820px){
    .about-section{
        .about-text{
            p{
                padding-left: 40px;
                padding-right: 40px;
            }
        }
    }
}
@media screen and (max-width: 541px) {
  .about-section{
    .about-text{
      h1{
        font-size: 30px;
      }
      p{
        font-size: 18px;
      }
      button{
        width: 140px;
        height: 40px;
        font-size: 16px ;
      }
    }
  }
}

@media screen and (max-width: 415px){
  .about-section{
    .about-text{
      h1{
        font-size: 26px;
      }
      p{
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 301px){
    .about-section{
        .about-text{
            font-size: 14px;
            button{
                width:140px;
                height: 40px;
                font-size: 16px;
            }
        }
    }
}
