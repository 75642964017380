.hero-section {
  background-color: rgba(13, 13, 14, 1);
  padding-top: 100px;
  display: flex;
  flex-direction: row;
  font-family: "Inter", sans-serif;
  padding-right: 100px;

  .profile-pic-container {
    flex: 1.2;
    width: 50vw;
    padding-top: 100px;
  }
  .profile-pic {
    width: 100%;
    background-image: url(../../img/hero-background-4.png);
  }

  .hero-text {
    color: white;
    flex: 1;
    margin-left: 150px;
    margin-top: 150px;
    .hello {
      font-size: 30px;
    }
    .my-name {
      font-size: 70px;
      white-space: nowrap;
      line-height: 0.2;
    }
    .website-dev {
      font-size: 70px;
      color: white;
      background: linear-gradient(92.11deg, #6a35f1 25.9%, #bc3ffe 84.84%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      line-height: 0.95;
      white-space: nowrap;
    }
    .i-take {
      font-size: 26px;
      width: 34vw;
      line-height: 1.2;
    }
    .buttons {
      .hire-me {
        height: 46px;
        width: 148px;
        margin-right: 20px;
        font-size: 15px;
        color: white;
        background: linear-gradient(92.11deg, #6a35f1 25.9%, #bc3ffe 84.84%);
        &:hover {
          color: #6a35f1;
          background: white;
          border-color: #6a35f1;
          cursor: pointer;
        }
      }
      .portfolio {
        height: 46px;
        width: 148px;
        margin-right: 20px;
        font-size: 15px;
        background-color: inherit;
        border-color: white;
        color: white;
        &:hover {
          color: black;
          border-color: black;
          background-color: white;
          cursor: pointer;
        }
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .hero-section {
    padding-bottom: 100px;

    .hero-text {
      .hello {
        font-size: 27px;
      }
      .my-name {
        font-size: 60px;
      }

      .website-dev {
        font-size: 60px;
      }
    }
  }
}
@media screen and (max-width: 1024px) {
  .hero-section {
    padding-right: 50px;
    .hero-text {
      margin-left: 80px;
      .hello {
        font-size: 27px;
      }
      .my-name {
        font-size: 45px;
      }
      .website-dev {
        font-size: 45px;
      }
      .i-take {
        font-size: 23px;
      }
    }
  }
}
@media screen and (max-width: 912px) {
  .hero-section {
    display: block;
    .hero-text {
      text-align: center;
      .i-take {
        width: 100%;
      }
    }

    .profile-pic-container {
      padding-top: 0px;
      margin-right: auto;
      margin-left: auto;
      .profile-pic {
        width: 60vw;
      }
    }
  }
}
@media screen and (max-width: 541px) {
  .hero-section {
    .hero-text {
      margin-left: 50px;
      .my-name {
        font-size: 37px;
      }
      .website-dev {
        font-size: 37px;
      }
      .i-take {
        font-size: 18px;
      }
    }
  }
}
@media screen and (max-width: 415px) {
  .hero-section {
    padding-top: 20px;
    .hero-text {
      .hello {
        font-size: 18px;
      }
      .my-name {
        font-size: 30px;
      }
      .website-dev {
        font-size: 30px;
      }
      .i-take {
        font-size: 16px;
      }
      .buttons {
        .hire-me {
          width: 80px;
          font-size: 10px;
        }
        .portfolio {
          width: 80px;
          font-size: 10px;
        }
      }
    }
    .profile-pic-container {
      width: 100vw;
      .profile-pic {
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 400px) {
  .hero-section {
    .hero-text {
      .website-dev {
        font-size: 27px;
      }
    }
  }
}
@media screen and (max-width: 376px) {
  .hero-section {
    .hero-text {
      .website-dev {
        font-size: 25px;
      }
    }
  }
}

@media screen and (max-width: 321px) {
  .hero-section {
    .hero-text {
      margin-left: 70px;
      .hello {
        margin-left: -25px;
        font-size: 14px;
      }
      .my-name {
        line-height: 1;
        margin-left: -25px;
        font-size: 19px;
      }
      .website-dev {
        margin-left: -25px;
        font-size: 19px;
      }
      .i-take {
        margin-left: -12px;
        font-size: 14px;
      }
      .buttons {
        .hire-me {
          width: 55px;
          height: 35px;
          font-size: 7.5px;
        }
        .portfolio {
          width: 55px;
          height: 35px;
          font-size: 7.5px;
        }
      }
    }
  }
}
