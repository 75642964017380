.contact-section {
  background-color: rgba(13, 13, 14, 1);
  color: white;
  padding-top: 40px;
  font-family: "Inter", sans-serif;
  padding-left: 150px;
  padding-right: 150px;

  h1 {
    text-align: center;
    background: linear-gradient(92.11deg, #6a35f1 25.9%, #bc3ffe 84.84%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    margin-bottom: 50px;
  }
  .contact-box {
    display: flex;
    padding-bottom: 50px;
    padding-left: 70px;
    padding-right: 70px;
    justify-content: space-between;
    padding-top: 10px;
    background: linear-gradient(
      92.11deg,
      rgba(106, 53, 241, 0.92) 25.9%,
      #bc3ffe 84.84%
    );
    background-image: url("../../img/Contact\ me\ background.png");
    background-size: contain;
    padding-top: 150px;
    .contact-text {
      font-size: 22px;
      .heading {
        all: inherit;
        width: 200px;
        width: 506px;
        height: 219px;
        font-weight: 800;
        font-size: 60px;
      }
    }
    .input-fields {
      width: 35vw;
      input {
        width: 100%;
        height: 54px;
        background: #b374f4;
        font-size: 18px;
        padding-left: 20px;
        border: none;
        margin-bottom: 10px;
        font-family: "Inter", sans-serif;
      }
      textarea {
        width: 100%;
        height: 136px;
        background: #b374f4;
        font-size: 18px;
        padding-left: 20px;
        border: none;
      }
      button {
        background-color: inherit;
        color: white;
        border: 1px solid white;
        width: 184px;
        height: 49px;
        margin-top: 20px;
        font-size: 17px;
      }
      button:hover {
        background-color: white;
        color: darkorchid;
        cursor: pointer;
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .contact-section {
    .contact-box {
      padding-top: 70px;
      .contact-text {
        .heading {
          font-size: 50px;
          width: 420px;
        }
        .paragraph {
          width: 400px;
          margin-top: -20px;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .contact-section {
    .contact-box {
      .contact-text {
        .heading {
          font-size: 40px;
          width: 350px;
        }
        .paragraph {
          width: 320px;
          margin-top: -50px;
        }
      }
    }
  }
}

@media screen and (max-width: 912px) {
  .contact-section {
    text-align: center;
    padding-left: 40px;
    padding-right: 40px;
    .contact-box {
      display: block;
      padding-left: 250px;
    }
  }
}

@media screen and (max-width: 820px) {
  .contact-section {
    .contact-box {
      display: block;
      padding-left: 220px;
      .contact-text {
        font-size: 20px;
        .heading {
          font-size: 30px;
          margin-left: -20px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .contact-section {
    .contact-box {
      display: block;
      padding-left: 200px;
      .contact-text {
        font-size: 20px;
        .heading {
          font-size: 30px;
          margin-left: -25px;
        }
      }
    }
  }
}

@media screen and (max-width: 541px) {
  .contact-section {
    padding-left: 40px;
    padding-right: 40px;
    .contact-box {
      padding-left: 20px;
      padding-right: 20px;
      .contact-text {
        padding-left: 50px;
        .heading {
          width: 300px;
          padding-left: 10px;
          margin-left: 0px;
        }
        .paragraph {
          margin-top: -130px;
        }
      }
      .input-fields {
        button {
          width: 144px;
          height: 40px;
          font-size: 12px;
        }
      }

      .input-fields {
        width: 95%;
      }
    }
  }
}

@media screen and (max-width: 541px) {
  .contact-section {
    h1 {
      font-size: 30px;
    }
  }
}

@media screen and (max-width: 450px) {
  .contact-section {
    h1 {
      font-size: 26px;
    }
    .contact-box {
      padding-left: 10px;
      .contact-text {
        padding-left: 0px;
        .heading {
          width: 290px;
          // margin-left: -6px;
        }
        .paragraph {
          width: 290px;
          margin-top: -120px;
          font-size: 18px;
          margin-left: 4px;
        }
      }
    }
    .input-fields {
      button {
        margin-left: 23px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .contact-section {
    .contact-box {
      .contact-text {
        .heading {
          margin-left: -15px;
        }
        .paragraph {
          margin-left: -4px;
        }
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .contact-section {
    .contact-box {
      .contact-text {
        .heading {
          font-size: 24px;
          width: 270px;
        }
        .paragraph {
          font-size: 16px;
          margin-left: -14px;
        }
      }
    }
  }
}

@media screen and (max-width: 321px) {
  .contact-section {
    .contact-box {
      padding-left: 0px;
      .contact-text {
        .heading {
          font-size: 19px;
          margin-left: -24px;
        }
        .paragraph {
          font-size: 14px;
          width: 250px;
          margin-top: -165px;
          margin-left: -6px;
        }
      }
      .input-fields {
        input {
          margin-left: 4px;
        }
        textarea {
          margin-left: 4px;
        }
        button {
          margin-left: 28px;
        }
      }
    }
  }
}

@media screen and (max-width: 300px) {
  .contact-section {
    .contact-box {
      padding-left: 8px;
      .contact-text {
        .heading {
          width: 160px;
          font-size: 17px;
          margin-left: 4px;
        }
        .paragraph {
          width: 150px;
          font-size: 12px;
          margin-left: 13px;
          margin-top: -170px;
        }
      }
      .input-fields {
        margin-left: 6px;
        input {
          height: 30px;
          font-size: 12px;
          padding-left: 5px;
        }
        textarea {
          height: 80px;
          font-size: 12px;
          padding-left: 5px;
        }
        button {
          width: 110px;
          margin-left: 10px;
        }
      }
    }
  }
}
