.projectcards {
  position: relative;
  font-family: "Inter", sans-serif;

  img {
    width: 380px;
    height: 284px;
  }
  .props-heading {
    position: absolute;
    top: 190px;
    left: 20px;
    color: white;
    // background-color: black;
    text-shadow: -1px -1px 0 #000, 0 -1px 0 #000, 1px -1px 0 #000, 1px 0 0 #000,
      1px 1px 0 #000, 0 1px 0 #000, -1px 1px 0 #000, -1px 0 0 #000;
  }
  .button-div {
    position: absolute;
    top: 240px;
    left: 20px;
    display: flex;
    align-items: center;
    .live-site {
      background-color: white;
      background: linear-gradient(92.11deg, #6a35f1 25.9%, #bc3ffe 84.84%);
      height: 40px;
      margin-right: 5px;
      font-weight: 700;
      color: white;
      width: 143px;
      &:hover {
        cursor: pointer;
      }
    }
    .git-repo {
      height: 40px;
      margin-right: 5px;
      font-weight: 700;
      border-color: white;
      background-color: black;
      color: white;
      width: 143px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 1025px) {
  .projectcards {
    img {
      width: 350px;
      height: 254px;
    }
    .props-heading {
      top: 150px;
      font-size: 18px;
    }
    .button-div {
      top: 200px;
      .live-site {
        width: 100px;
        font-size: 10px;
      }
      .git-repo {
        width: 100px;
        font-size: 10px;
      }
    }
  }
}

@media screen and (max-width: 300px) {
  .projectcards {
    .button-div {
      top: 210px;
      .live-site {
        width: 70px;
        height: 30px;
        font-size: 8px;
      }
      .git-repo {
        width: 70px;
        height: 30px;
        font-size: 8px;
      }
    }
  }
}
