.skills-section {
  background-color: rgba(13, 13, 14, 1);
  font-family: "Inter", sans-serif;

  .h1-container {
    display: flex;
    justify-content: center;

    h1 {
      color: white;
      background: linear-gradient(92.11deg, #6a35f1 25.9%, #bc3ffe 84.84%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      font-size: 38px;
    }
  }
  .top-skills {
    display: flex;
    justify-content: space-between;
    margin-right: 150px;
    margin-left: 150px;
    align-items: center;
   .node{
    width: 150px;
   }
   .mongodb{
    width: 120px;
   }
  }
  .top-skills-mobile {
    display: none;
  }
}
@media screen and (max-width: 912px) {
  .skills-section {
    .top-skills {
      display: none;
    }
    .top-skills-mobile {
      display: block;

      .top-row {
        display: flex;
        justify-content: space-between;
        margin-right: 120px;
        margin-left: 120px;
        margin-bottom: 80px;
        .node{
          width: 120px;
        }
        .mongodb{
          width: 100px;
        }
      }
      .bottom-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 170px;
        margin-left: 170px;
      }
    }
  }
}
@media screen and (max-width: 541px) {
    .skills-section{
      .h1-container{
        h1{
          font-size: 30px;
        }
      }
        .top-skills-mobile{
            .top-row{
              .node{
                width: 90px;
              }
              .mongodb{
                width: 80px;
              }
            }
            .bottom-row{
                margin-left: 110px;
                margin-right: 100px;
               
            }
           
        }
    }
}

@media screen and (max-width: 500px) {
    .skills-section{
      .h1-container{
        h1{
          font-size: 26px;
        }
      }
        .top-skills-mobile{
            .top-row{
                margin-right: 50px;
                margin-left: 50px;
                img{
                    width: 50px;
                }
            }
            .bottom-row{

                img{
                    width: 80px;
                }
            }
        }
    }
}
@media screen and (max-width: 376px) {
  .skills-section{
    .top-skills-mobile{
      .top-row{
        img{
          height: 70px;
        }
      }
    }
  }
}
@media screen and (max-width: 321px) {
    .skills-section{
        .top-skills-mobile{
            .top-row{
                margin-right: 30px;
                margin-left: 30px;
            }
            .bottom-row{
                margin-left: 45px;
                margin-right: 45px;
            }
        }
    }
}
