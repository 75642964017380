.portfolio-section {
  background-color: rgba(13, 13, 14, 1);
  padding-top: 50px;
  font-family: "Inter", sans-serif;
  padding-left: 150px;
  padding-right: 150px;

  h1 {
    color: white;
    text-align: center;
    font-size: 38px;
    background: linear-gradient(92.11deg, #6a35f1 25.9%, #bc3ffe 84.84%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    
  }
}
@media screen and (max-width: 912px) {
  .portfolio-section{
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media screen and (max-width: 541px) {
  .portfolio-section{
    h1{
      font-size: 30px;
    }
  }
}

@media screen and (max-width: 415px) {
  .portfolio-section{
    h1{
      font-size: 26px;
    }
  }
}
